import './instructions.css'
import Step from './step';

function Instructions() {
  return (
    <div className='instructions'>
      <br />
      <Step></Step>
      <Step></Step>
      <Step></Step>

    </div>
  );
}

export default Instructions;
