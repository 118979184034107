import Logo from './shared/components/logo/logo'
import './App.css';
import Slogan from './screens/landing/components/slogan/slogan';
import Footer from './screens/landing/components/footer/footer';
import Stores from './screens/landing/components/stores/stores';
import Instructions from './screens/landing/components/instructions/instructions';
import EmailList from './screens/landing/components/emailList/emailList';

function App() {
  return (
    <div className="App">
      <Logo></Logo>
      <Slogan></Slogan>
      <Instructions></Instructions>
      <Stores></Stores>
      <EmailList></EmailList>
      <Footer/>
    </div>
  );
}

export default App;
