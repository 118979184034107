import './slogan.css'
import '@material/web/button/filled-button.js';

function Slogan() {
  return (

      <div className='slogan'>
        <text className="title">Economize e evite o desperdício</text>
        <br />
        <text className="subtitle">Sua sacola vale mais </text>
        <br />
        <div className="knowMoreContainer">
          <md-filled-button class="knowMoreButton">Conheça a balaio</md-filled-button>
        </div>
        <div className='sloganCurve'>
      </div>
    </div>
  );
}

export default Slogan;
