import './style.css'
import '@material/web/textfield/outlined-text-field';

function EmailList() {
  return (
    <div className='emailList'>

      <text className="signInText">Inscreva-se para receber novidades</text>
      <div className='emailContainer'>
        <md-outlined-text-field label="Seu melhor e-mail" ></md-outlined-text-field>
      </div>

    </div>
  );
}

export default EmailList;
