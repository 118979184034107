import logo from './logo.svg';

function Logo() {
  return (
    <div >
      <header className="App-header">
        <img src={logo}  alt="logo" />
      </header>
    </div>
  );
}

export default Logo;
