import './stores.css'
import stores from './storesLogos.svg'

function Stores() {
  return (
    <div className='storeCurve'>
      <div className='storeContainer' >
        <text className="titles">Em breve disponível</text>
        <br />
        <img className="stores" src={stores} alt="stores" />
      </div>
    </div>
  );
}

export default Stores;
