import './footer.css'
import social from './social.svg'

function Footer() {
  return (
    <div className='footer'>
      <img src={social} alt="social images"></img>
      <div className="trademarkContainer" >
        <text className="trademark">Balaio © • 2024</text>
      </div>
    </div>
  );
}

export default Footer;
