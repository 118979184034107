import './instructions.css'
import img from './img_placeholder.svg';

function Step() {
    return (
        <div className='step'>
            <text>1</text>
            <img className="stores" src={img} alt="stores" />
            <br />
            <text>Escolha seus produtos</text>

        </div>
    );
}

export default Step;
